<template>
  <div v-loading="loading" class="column">
    <div class="top_text">全国幼儿各项指标增长变化</div>
    <div id="lineChart" class="topLine"></div>
    <div class="top_hint">
      <div class="line"></div>
      <div class="flex text">幼儿{{ typeValue }}测试数据小班</div>
      <div class="mrl2 line"></div>
      <div class="flex text">幼儿{{ typeValue }}测试数据中班</div>
      <div class="mrl3 line"></div>
      <div class="flex text">幼儿{{ typeValue }}测试数据大班</div>
      <div class="mrl1 line"></div>
    </div>
    <div class="row mt26">
      <div
        class="label ml8"
        @click="clickColor(1)"
        @mouseover="Mouseover(1)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 1 || tzMouthType == 1
              ? 'width62 color1 text_select'
              : 'alpha width62 text_normal'
          "
        >
          {{ names1[0] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(2)"
        @mouseover="Mouseover(2)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 2 || tzMouthType == 2
              ? 'width62 color2 text_select'
              : 'alpha width62 text_normal'
          "
        >
          {{ names1[1] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(3)"
        @mouseover="Mouseover(3)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 3 || tzMouthType == 3
              ? 'width62 color3 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[2] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(4)"
        @mouseover="Mouseover(4)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 4 || tzMouthType == 4
              ? 'width62 color4 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[3] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(5)"
        @mouseover="Mouseover(5)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 5 || tzMouthType == 5
              ? 'width62 color5 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[4] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(6)"
        @mouseover="Mouseover(6)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 6 || tzMouthType == 6
              ? 'width62 color6 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[5] }}
        </div>
      </div>
      <div
        class="label ml20"
        @click="clickColor(7)"
        @mouseover="Mouseover(7)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 7 || tzMouthType == 7
              ? 'width62 color7 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[6] }}
        </div>
      </div>
      <div
        class="label ml20"
        @click="clickColor(8)"
        @mouseover="Mouseover(8)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 8 || tzMouthType == 8
              ? 'width62 color8 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[7] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(9)"
        @mouseover="Mouseover(9)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 9 || tzMouthType == 9
              ? 'width62 color9 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[8] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(10)"
        @mouseover="Mouseover(10)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 10 || tzMouthType == 10
              ? 'width62 color10 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[9] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(11)"
        @mouseover="Mouseover(11)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 11 || tzMouthType == 11
              ? 'width62 color11 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[10] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(12)"
        @mouseover="Mouseover(12)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 12 || tzMouthType == 12
              ? 'width62 color12 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[11] }}
        </div>
      </div>
      <div
        class="label ml32"
        @click="clickColor(13)"
        @mouseover="Mouseover(13)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 13 || tzMouthType == 13
              ? 'width62 color13 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[12] }}
        </div>
      </div>
      <div
        class="label ml20"
        @click="clickColor(14)"
        @mouseover="Mouseover(14)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 14 || tzMouthType == 14
              ? 'width62 color14 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[13] }}
        </div>
      </div>
      <div
        class="label ml20"
        @click="clickColor(15)"
        @mouseover="Mouseover(15)"
        @mouseleave="Mouseleave()"
      >
        <div
          :class="
            tzCurrent == 15 || tzMouthType == 15
              ? 'width62 color15 text_select'
              : 'width62 alpha text_normal'
          "
        >
          {{ names1[14] }}
        </div>
      </div>
    </div>
    <div class="mt26 title">幼儿成长分析</div>
    <img class="pes" src="../../assets/img/home/home/PES/pes.png" />
    <div class="content mt15">
      通过与全国上月增长率对比，观察您幼儿园上月体质和运动增长率高低，低于全国增长率水平的指标需要重点关注
    </div>
    <div class="baseLine mt72">
      <div class="width514">
        <div class="top_lay">
          <div class="top_bg pos_abs">
            <div class="middle">上月体质增长率</div>
          </div>
        </div>
        <div class="hint1">上月体质测试全国增长率对比园所增长率</div>
        <div class="num_lay mt36">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_height ? rateData.B_height[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">身高</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_height && rateData.B_height[1] != '--'"
              class="text"
              >{{ rateData.B_height[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.B_height && rateData.B_height[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_weight ? rateData.B_weight[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">体重</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_weight && rateData.B_weight[1] != '--'"
              class="text"
              >{{ rateData.B_weight[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.B_weight && rateData.B_weight[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_eye_L ? rateData.B_eye_L[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">视力</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_eye_L && rateData.B_eye_L[1] != '--'"
              class="text"
              >{{ rateData.B_eye_L[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.B_eye_L && rateData.B_eye_L[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_arm ? rateData.B_arm[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">臂展</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_arm && rateData.B_arm[1] != '--'"
              class="text"
              >{{ rateData.B_arm[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.B_arm && rateData.B_arm[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_lung ? rateData.B_lung[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">肺活量</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_lung && rateData.B_lung[1] != '--'"
              class="text"
              >{{ rateData.B_lung[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.B_lung && rateData.B_lung[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_bust ? rateData.B_bust[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">胸围</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_bust && rateData.B_bust[1] != '--'"
              class="text"
              >{{ rateData.B_bust[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.B_bust && rateData.B_bust[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_flexion ? rateData.B_flexion[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">体前屈</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_flexion && rateData.B_flexion[1] != '--'"
              class="text"
              >{{ rateData.B_flexion[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.B_flexion && rateData.B_flexion[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
      </div>
      <div class="width514 ml55">
        <div class="top_lay">
          <div class="top_bg pos_abs">
            <div class="middle">上月运动增长率</div>
          </div>
        </div>
        <div class="hint1">上月运动测试全国增长率对比园所增长率</div>
        <div class="num_lay mt36">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_run ? rateData.S_run[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">跑动</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_run && rateData.S_run[1] != '--'"
              class="text"
              >{{ rateData.S_run[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.S_run && rateData.S_run[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_jump ? rateData.S_jump[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">跳跃</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_jump && rateData.S_jump[1] != '--'"
              class="text"
              >{{ rateData.S_jump[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.S_jump && rateData.S_jump[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_agile ? rateData.S_agile[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">敏捷</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_agile && rateData.S_agile[1] != '--'"
              class="text"
              >{{ rateData.S_agile[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.S_agile && rateData.S_agile[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_power ? rateData.S_power[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">力量</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_power && rateData.S_power[1] != '--'"
              class="text"
              >{{ rateData.S_power[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.S_power && rateData.S_power[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_coordinate ? rateData.S_coordinate[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">协调</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_coordinate && rateData.S_coordinate[1] != '--'"
              class="text"
              >{{ rateData.S_coordinate[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.S_coordinate && rateData.S_coordinate[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_balance ? rateData.S_balance[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">平衡</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_balance && rateData.S_balance[1] != '--'"
              class="text"
              >{{ rateData.S_balance[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.S_balance && rateData.S_balance[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_ctrl_hand ? rateData.S_ctrl_hand[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">物控手</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_ctrl_hand && rateData.S_ctrl_hand[1] != '--'"
              class="text"
              >{{ rateData.S_ctrl_hand[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.S_ctrl_hand && rateData.S_ctrl_hand[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_ctrl_foot ? rateData.S_ctrl_foot[0] : "-"
            }}</span>
            <div class="text1">%</div>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">物控脚</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_ctrl_foot && rateData.S_ctrl_foot[1] != '--'"
              class="text"
              >{{ rateData.S_ctrl_foot[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
            <div
              v-if="rateData.S_ctrl_foot && rateData.S_ctrl_foot[1] != '--'"
              class="text1"
            >
              %
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="analyse center" @click="clickAnalyse">一键分析</div>
    <div id="analyseChart" class="analyse_chart"></div>
    <div class="row width1400">
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[0] ? times[0].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="analyseData[0] && analyseData[0] == '--'" class="red_text">
          未测试
        </div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[1] ? times[1].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="analyseData[1] && analyseData[1] == '--'" class="red_text">
          未测试
        </div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[2] ? times[2].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="analyseData[2] && analyseData[2] == '--'" class="red_text">
          未测试
        </div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[3] ? times[3].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="analyseData[3] && analyseData[3] == '--'" class="red_text">
          未测试
        </div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[4] ? times[4].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="analyseData[4] && analyseData[4] == '--'" class="red_text">
          未测试
        </div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[5] ? times[5].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="analyseData[5] && analyseData[5] == '--'" class="red_text">
          未测试
        </div>
      </div>
    </div>
    <div class="row mt26">
      <div class="row">
        <div
          class="label ml32"
          @click="clickAnalyseColor(1)"
          @mouseover="Mouseover2(1)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 1 || tzMouthType1 == 1
                ? 'width62 color1 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[0] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(2)"
          @mouseover="Mouseover2(2)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 2 || tzMouthType1 == 2
                ? 'width62 color2 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[1] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(3)"
          @mouseover="Mouseover2(3)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 3 || tzMouthType1 == 3
                ? 'width62 color3 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[2] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(4)"
          @mouseover="Mouseover2(4)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 4 || tzMouthType1 == 4
                ? 'width62 color4 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[3] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(5)"
          @mouseover="Mouseover2(5)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 5 || tzMouthType1 == 5
                ? 'width62 color5 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[4] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(6)"
          @mouseover="Mouseover2(6)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 6 || tzMouthType1 == 6
                ? 'width62 color6 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[5] }}
          </div>
        </div>
        <div
          class="label ml20"
          @click="clickAnalyseColor(7)"
          @mouseover="Mouseover2(7)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 7 || tzMouthType1 == 7
                ? 'width62 color7 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[6] }}
          </div>
        </div>

        <div
          class="label ml32"
          @click="clickAnalyseColor(8)"
          @mouseover="Mouseover2(8)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 8 || tzMouthType1 == 8
                ? 'width62 color8 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[7] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(9)"
          @mouseover="Mouseover2(9)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 9 || tzMouthType1 == 9
                ? 'width62 color9 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[8] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(10)"
          @mouseover="Mouseover2(10)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 10 || tzMouthType1 == 10
                ? 'width62 color10 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[9] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(11)"
          @mouseover="Mouseover2(11)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 11 || tzMouthType1 == 11
                ? 'width62 color11 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[10] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(12)"
          @mouseover="Mouseover2(12)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 12 || tzMouthType1 == 12
                ? 'width62 color12 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[11] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseColor(13)"
          @mouseover="Mouseover2(13)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 13 || tzMouthType1 == 13
                ? 'width62 color13 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[12] }}
          </div>
        </div>
        <div
          class="label ml20"
          @click="clickAnalyseColor(14)"
          @mouseover="Mouseover2(14)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 14 || tzMouthType1 == 14
                ? 'width62 color14 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[13] }}
          </div>
        </div>
        <div
          class="label ml20"
          @click="clickAnalyseColor(15)"
          @mouseover="Mouseover2(15)"
          @mouseleave="Mouseleave2()"
        >
          <div
            :class="
              tzCurrent1 == 15 || tzMouthType1 == 15
                ? 'width62 color15 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[14] }}
          </div>
        </div>
      </div>
    </div>
    <div class="ys_name">{{ ysName }}</div>
    <div class="result">分析结果：{{ message ? message : "暂无" }}</div>
    <div class="h240"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "growthAnalyse",
  components: {},
  data() {
    return {
      charts: null,
      analyseCharts: null,
      type: 1,
      labelMouthType: 0,
      typeValue: "体质",
      datatz1: [],
      datatz2: [],
      datatz3: [],
      datatz4: [],
      datatz5: [],
      datatz6: [],
      datatz7: [],
      datatz8: [],
      datayd1: [],
      datayd2: [],
      datayd3: [],
      datayd4: [],
      datayd5: [],
      datayd6: [],
      datayd7: [],
      datayd8: [],
      colors: [
        "rgba(192, 40, 27, 1)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color1: [
        "rgba(192, 40, 27, 1)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color2: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 1)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color3: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 1)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color4: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 1)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color5: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 1)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color6: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 1)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color7: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 1)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color8: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 1)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color9: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 1)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color10: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 1)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color11: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 1)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color12: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 1)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color13: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 1)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color14: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 1)",
        "rgba(15, 193, 100, 0.05)",
      ],
      color15: [
        "rgba(192, 40, 27, 0.05)",
        "rgba(64, 209, 0, 0.05)",
        "rgba(0, 168, 193, 0.05)",
        "rgba(224, 200, 0, 0.05)",
        "rgba(244, 138, 0, 0.05)",
        "rgba(39, 0, 237, 0.05)",
        "rgba(214, 0, 253, 0.05)",
        "rgba(151, 32, 79, 0.05)",
        "rgba(150, 36, 49, 0.05)",
        "rgba(46, 162, 122, 0.05)",
        "rgba(31, 68, 135, 0.05)",
        "rgba(182, 150, 35, 0.05)",
        "rgba(10, 0, 81, 0.05)",
        "rgba(24, 130, 148, 0.05)",
        "rgba(15, 193, 100, 1)",
      ],
      names1: [
        "身高",
        "体重",
        "视力",
        "胸围",
        "体前屈",
        "臂展",
        "肺活量",
        "跑动",
        "跳跃",
        "敏捷",
        "平衡",
        "协调",
        "力量",
        "物控手",
        "物控脚",
      ],
      analyseName: "身高",
      analyseColor: "#C0281B",
      qgData: [],
      analyseData: [],
      analyseSource: [],
      qgHeight: [],
      ysHeight: [],
      analyseType: 1,
      analyseLabelMouthType: 0,
      analyseValue: {},
      times: [],
      rateData: {},
      message: "",
      ysName: "",
      loading: false,
      tzCurrent: 1,
      tzMouthType: -1,
      ydCurrent: 1,
      ydMouthType: -1,
      tzCurrent1: 1,
      tzMouthType1: -1,
      ydCurrent1: 1,
      ydMouthType1: -1,
    };
  },

  methods: {
    labelMouseover(index) {
      this.labelMouthType = index;
    },
    labelMouseleave() {
      this.labelMouthType = 0;
    },

    // 分析折线图，点击体质项
    clickAnalyseColor(index) {
      this.tzCurrent1 = index;
      this.qgHeight = [];
      this.ysHeight = [];
      if (index == 1) {
        this.analyseColor = "#C0281B";
        this.qgData = this.analyseValue.B_height_country;
        this.analyseData = this.analyseValue.B_height_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].B_height_kd[0]);
          this.ysHeight.push(this.analyseSource[i].B_height_kd[1]);
        }
      } else if (index == 2) {
        this.analyseColor = "#40d100";
        this.qgData = this.analyseValue.B_weight_country;
        this.analyseData = this.analyseValue.B_weight_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].B_weight_kd[0]);
          this.ysHeight.push(this.analyseSource[i].B_weight_kd[1]);
        }
      } else if (index == 3) {
        this.analyseColor = "#00a8c1";
        this.qgData = this.analyseValue.B_eye_L_country;
        this.analyseData = this.analyseValue.B_eye_L_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].B_eye_L_kd[0]);
          this.ysHeight.push(this.analyseSource[i].B_eye_L_kd[1]);
        }
      } else if (index == 4) {
        this.analyseColor = "#f48a00";
        this.qgData = this.analyseValue.B_bust_country;
        this.analyseData = this.analyseValue.B_bust_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].B_bust_kd[0]);
          this.ysHeight.push(this.analyseSource[i].B_bust_kd[1]);
        }
      } else if (index == 5) {
        this.analyseColor = "#f48a00";
        this.qgData = this.analyseValue.B_flexion_country;
        this.analyseData = this.analyseValue.B_flexion_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].B_flexion_kd[0]);
          this.ysHeight.push(this.analyseSource[i].B_flexion_kd[1]);
        }
      } else if (index == 6) {
        this.analyseColor = "#2700ed";
        this.qgData = this.analyseValue.B_arm_country;
        this.analyseData = this.analyseValue.B_arm_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].B_arm_kd[0]);
          this.ysHeight.push(this.analyseSource[i].B_arm_kd[1]);
        }
      } else if (index == 7) {
        this.analyseColor = "#d600fd";
        this.qgData = this.analyseValue.B_lung_country;
        this.analyseData = this.analyseValue.B_lung_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].B_lung_kd[0]);
          this.ysHeight.push(this.analyseSource[i].B_lung_kd[1]);
        }
      } else if (index == 8) {
        this.analyseColor = "rgba(151, 32, 79, 1)";
        this.qgData = this.analyseValue.S_run_country;
        this.analyseData = this.analyseValue.S_run_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].S_run_kd[0]);
          this.ysHeight.push(this.analyseSource[i].S_run_kd[1]);
        }
      } else if (index == 9) {
        this.analyseColor = "rgba(150, 36, 49, 1)";
        this.qgData = this.analyseValue.S_jump_country;
        this.analyseData = this.analyseValue.S_jump_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].S_jump_kd[0]);
          this.ysHeight.push(this.analyseSource[i].S_jump_kd[1]);
        }
      } else if (index == 10) {
        this.analyseColor = "rgba(46, 162, 122, 1)";
        this.qgData = this.analyseValue.S_agile_country;
        this.analyseData = this.analyseValue.S_agile_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].S_agile_kd[0]);
          this.ysHeight.push(this.analyseSource[i].S_agile_kd[1]);
        }
      } else if (index == 11) {
        this.analyseColor = "rgba(31, 68, 135, 1)";
        this.qgData = this.analyseValue.S_balance_country;
        this.analyseData = this.analyseValue.S_balance_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].S_balance_kd[0]);
          this.ysHeight.push(this.analyseSource[i].S_balance_kd[1]);
        }
      } else if (index == 12) {
        this.analyseColor = "rgba(182, 150, 35, 1)";
        this.qgData = this.analyseValue.S_coordinate_country;
        this.analyseData = this.analyseValue.S_coordinate_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].S_coordinate_kd[0]);
          this.ysHeight.push(this.analyseSource[i].S_coordinate_kd[1]);
        }
      } else if (index == 13) {
        this.analyseColor = "rgba(10, 0, 81, 1)";
        this.qgData = this.analyseValue.S_power_country;
        this.analyseData = this.analyseValue.S_power_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].S_power_kd[0]);
          this.ysHeight.push(this.analyseSource[i].S_power_kd[1]);
        }
      } else if (index == 14) {
        this.analyseColor = "rgba(24, 130, 148, 1)";
        this.qgData = this.analyseValue.S_ctrl_hand_country;
        this.analyseData = this.analyseValue.S_ctrl_hand_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].S_ctrl_hand_kd[0]);
          this.ysHeight.push(this.analyseSource[i].S_ctrl_hand_kd[1]);
        }
      } else if (index == 15) {
        this.analyseColor = "rgba(15, 193, 100, 1)";
        this.qgData = this.analyseValue.S_ctrl_foot_country;
        this.analyseData = this.analyseValue.S_ctrl_foot_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].S_ctrl_foot_kd[0]);
          this.ysHeight.push(this.analyseSource[i].S_ctrl_foot_kd[1]);
        }
      }
      this.drawAnalyseLine("analyseChart");
    },

    // 一键分析
    clickAnalyse() {
      this.loading = true;
      this.tzCurrent1 = 1;
      this.analyseColor = "#C0281B";
      this.qgHeight = [];
      this.ysHeight = [];
      this.getAnalyseData();
    },
    Mouseover(index) {
      this.tzMouthType = index;
    },
    Mouseleave() {
      this.tzMouthType = -1;
    },
    Mouseover2(index) {
      this.tzMouthType1 = index;
    },
    Mouseleave2() {
      this.tzMouthType1 = -1;
    },
    clickColor(index) {
      this.tzCurrent = index;
      if (index == 1) {
        this.colors = this.color1;
      } else if (index == 2) {
        this.colors = this.color2;
      } else if (index == 3) {
        this.colors = this.color3;
      } else if (index == 4) {
        this.colors = this.color4;
      } else if (index == 5) {
        this.colors = this.color5;
      } else if (index == 6) {
        this.colors = this.color6;
      } else if (index == 7) {
        this.colors = this.color7;
      } else if (index == 8) {
        this.colors = this.color8;
      } else if (index == 9) {
        this.colors = this.color9;
      } else if (index == 10) {
        this.colors = this.color10;
      } else if (index == 11) {
        this.colors = this.color11;
      } else if (index == 12) {
        this.colors = this.color12;
      } else if (index == 13) {
        this.colors = this.color13;
      } else if (index == 14) {
        this.colors = this.color14;
      } else if (index == 15) {
        this.colors = this.color15;
      }
      this.drawLine("lineChart");
    },

    drawLine(id) {
      if (this.charts == null) {
        this.charts = echarts.init(document.getElementById(id));
      }
      this.charts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              if (datas[i].seriesName == "身高") {
                val += "cm";
              } else if (datas[i].seriesName == "体重") {
                val += "kg";
              } else if (datas[i].seriesName == "臂展") {
                val += "cm";
              } else if (datas[i].seriesName == "胸围") {
                val += "cm";
              } else if (datas[i].seriesName == "肺活量") {
                val += "ml";
              } else if (datas[i].seriesName == "体前屈") {
                val += "cm";
              } else if (datas[i].seriesName == "跑动") {
                val += "s";
              } else if (datas[i].seriesName == "跳跃") {
                val += "s";
              } else if (datas[i].seriesName == "敏捷") {
                val += "s";
              } else if (datas[i].seriesName == "平衡") {
                val += "s";
              } else if (datas[i].seriesName == "协调") {
                val += "s";
              } else if (datas[i].seriesName == "力量") {
                val += "s";
              } else if (datas[i].seriesName == "物控手") {
                val += "个";
              } else if (datas[i].seriesName == "物控脚") {
                val += "个";
              }
              res += datas[i].seriesName + "：" + val + "<br/>";
            }
            return res;
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "25px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        xAxis: {
          type: "category",
          data: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ],
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: false, //去除轴线
          },
          axisLabel: {
            color: "#999999", //x轴文本颜色
            inside: true,
          },
          splitLine: {
            // 分隔线
            show: true, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 150,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 45,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 5.4,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 110,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 50,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 180,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 2000,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 60,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 60,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 90,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 90,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 90,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 120,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 70,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
        ],

        series: [
          {
            name: this.names1[0],
            type: "line",
            data: this.datatz1,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.colors[0],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[1],
            type: "line",
            data: this.datatz2,
            smooth: true,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: this.colors[1],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[2],
            type: "line",
            data: this.datatz3,
            smooth: true,
            yAxisIndex: 2,
            itemStyle: {
              normal: {
                color: this.colors[2],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[3],
            type: "line",
            data: this.datatz4,
            smooth: true,
            yAxisIndex: 3,
            itemStyle: {
              normal: {
                color: this.colors[3],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[4],
            type: "line",
            data: this.datatz5,
            smooth: true,
            yAxisIndex: 4,
            itemStyle: {
              normal: {
                color: this.colors[4],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[5],
            type: "line",
            data: this.datatz6,
            smooth: true,
            yAxisIndex: 5,
            itemStyle: {
              normal: {
                color: this.colors[5],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[6],
            type: "line",
            data: this.datatz7,
            smooth: true,
            yAxisIndex: 6,
            itemStyle: {
              normal: {
                color: this.colors[6],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[7],
            type: "line",
            data: this.datayd1,
            smooth: true,
            yAxisIndex: 7,
            itemStyle: {
              normal: {
                color: this.colors[7],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[8],
            type: "line",
            data: this.datayd2,
            smooth: true,
            yAxisIndex: 8,
            itemStyle: {
              normal: {
                color: this.colors[8],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[9],
            type: "line",
            data: this.datayd3,
            smooth: true,
            yAxisIndex: 9,
            itemStyle: {
              normal: {
                color: this.colors[9],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[10],
            type: "line",
            data: this.datayd4,
            smooth: true,
            yAxisIndex: 10,
            itemStyle: {
              normal: {
                color: this.colors[10],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[11],
            type: "line",
            data: this.datayd5,
            smooth: true,
            yAxisIndex: 11,
            itemStyle: {
              normal: {
                color: this.colors[11],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[12],
            type: "line",
            data: this.datayd6,
            smooth: true,
            yAxisIndex: 12,
            itemStyle: {
              normal: {
                color: this.colors[12],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[13],
            type: "line",
            data: this.datayd7,
            smooth: true,
            yAxisIndex: 13,
            itemStyle: {
              normal: {
                color: this.colors[13],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.names1[14],
            type: "line",
            data: this.datayd8,
            smooth: true,
            yAxisIndex: 14,
            itemStyle: {
              normal: {
                color: this.colors[14],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
        ],
      });
    },
    // 分析折线图
    drawAnalyseLine(id) {
      if (this.analyseCharts == null) {
        this.analyseCharts = echarts.init(document.getElementById(id));
      }
      var that = this;
      this.analyseCharts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "%<br/>";
            }
            return "";
          },

          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          orient: "vertical",
          x: "left", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 0, 0, 0],
          data: ["全国", "园所"],
        },
        grid: {
          top: "50px",
          left: "0px",
          right: "0px",
          bottom: "10px",
        },
        xAxis: {
          type: "category",
          data: this.times,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: true, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false, //去除轴线
          },
          max: 10,
          axisLabel: {
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },

        series: [
          {
            name: "全国",
            type: "bar",
            data: this.qgHeight,
            itemStyle: {
              normal: {
                color: "#000E56",
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    return that.qgData[data.dataIndex] + "%";
                  },
                },
              },
            },
          },
          {
            name: "园所",
            type: "bar",
            data: this.ysHeight,
            itemStyle: {
              normal: {
                color: this.analyseColor,
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    if (that.analyseData[data.dataIndex] == "--") {
                      return "0.0%";
                    }
                    return that.analyseData[data.dataIndex] + "%";
                  },
                },
              },
            },
          },
        ],
      });
    },
    // 获取顶部折线图数据
    getTopLineData() {
      let data = {};
      this.api.pes.growthValueTopData(data).then((res) => {
        this.datatz1.push(...res.data[0].formatValues.B_height);
        this.datatz1.push(...res.data[1].formatValues.B_height);
        this.datatz1.push(...res.data[2].formatValues.B_height);
        this.datatz2.push(...res.data[0].formatValues.B_weight);
        this.datatz2.push(...res.data[1].formatValues.B_weight);
        this.datatz2.push(...res.data[2].formatValues.B_weight);
        this.datatz3.push(...res.data[0].formatValues.B_eye_L);
        this.datatz3.push(...res.data[1].formatValues.B_eye_L);
        this.datatz3.push(...res.data[2].formatValues.B_eye_L);
        this.datatz4.push(...res.data[0].formatValues.B_bust);
        this.datatz4.push(...res.data[1].formatValues.B_bust);
        this.datatz4.push(...res.data[2].formatValues.B_bust);
        this.datatz5.push(...res.data[0].formatValues.B_flexion);
        this.datatz5.push(...res.data[1].formatValues.B_flexion);
        this.datatz5.push(...res.data[2].formatValues.B_flexion);
        this.datatz6.push(...res.data[0].formatValues.B_arm);
        this.datatz6.push(...res.data[1].formatValues.B_arm);
        this.datatz6.push(...res.data[2].formatValues.B_arm);
        this.datatz7.push(...res.data[0].formatValues.B_lung);
        this.datatz7.push(...res.data[1].formatValues.B_lung);
        this.datatz7.push(...res.data[2].formatValues.B_lung);
        this.datayd1.push(...res.data[0].formatValues.S_run);
        this.datayd1.push(...res.data[1].formatValues.S_run);
        this.datayd1.push(...res.data[2].formatValues.S_run);
        this.datayd2.push(...res.data[0].formatValues.S_jump);
        this.datayd2.push(...res.data[1].formatValues.S_jump);
        this.datayd2.push(...res.data[2].formatValues.S_jump);
        this.datayd3.push(...res.data[0].formatValues.S_agile);
        this.datayd3.push(...res.data[1].formatValues.S_agile);
        this.datayd3.push(...res.data[2].formatValues.S_agile);
        this.datayd4.push(...res.data[0].formatValues.S_balance);
        this.datayd4.push(...res.data[1].formatValues.S_balance);
        this.datayd4.push(...res.data[2].formatValues.S_balance);
        this.datayd5.push(...res.data[0].formatValues.S_coordinate);
        this.datayd5.push(...res.data[1].formatValues.S_coordinate);
        this.datayd5.push(...res.data[2].formatValues.S_coordinate);
        this.datayd6.push(...res.data[0].formatValues.S_power);
        this.datayd6.push(...res.data[1].formatValues.S_power);
        this.datayd6.push(...res.data[2].formatValues.S_power);
        this.datayd7.push(...res.data[0].formatValues.S_ctrl_hand);
        this.datayd7.push(...res.data[1].formatValues.S_ctrl_hand);
        this.datayd7.push(...res.data[2].formatValues.S_ctrl_hand);
        this.datayd8.push(...res.data[0].formatValues.S_ctrl_foot);
        this.datayd8.push(...res.data[1].formatValues.S_ctrl_foot);
        this.datayd8.push(...res.data[2].formatValues.S_ctrl_foot);
        this.$nextTick(function () {
          this.drawLine("lineChart");
        });
      });
    },
    // 获取增长率数据
    getRateData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.growthValueRateData(data).then((res) => {
        this.rateData = res.data;
      });
    },
    // 获取一键分析数据
    getAnalyseData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.growthValueAnalyseData(data).then((res) => {
        this.loading = false;
        this.analyseValue = res.data.formatValues;
        this.analyseSource = res.data.values;
        this.qgData = this.analyseValue.B_height_country;
        this.analyseData = this.analyseValue.B_height_school;
        for (let i = 0; i < this.analyseSource.length; i++) {
          this.qgHeight.push(this.analyseSource[i].B_height_kd[0]);
          this.ysHeight.push(this.analyseSource[i].B_height_kd[1]);
        }
        this.times = this.analyseValue.time;
        var result = JSON.parse(res.message);
        this.ysName = result.message;
        this.message = result.analy;
        this.$nextTick(function () {
          this.drawAnalyseLine("analyseChart");
        });
      });
    },
  },
  mounted() {
    this.getTopLineData();
    this.getRateData();
    this.getAnalyseData();
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.topLine {
  width: 1700px;
  height: 551px;
}
.row {
  display: flex;
  flex-direction: row;
}
.flex {
  flex: 1;
}
.top_hint {
  display: flex;
  flex-direction: row;
  width: 1700px;
  height: 53px;
  .line {
    width: 2px;
    height: 53px;
    background: #c2c2c2;
  }
  .mrl1 {
    margin-left: 1px;
  }
  .mrl2 {
    margin-left: 2px;
  }
  .mrl3 {
    margin-left: 3px;
  }
  .text {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #00157f;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
.mt26 {
  margin-top: 26px;
}
.tz_select {
  width: 67px;
  height: 24px;
  background: #001689;
  border: 3px solid #07155e;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tz_normal {
  width: 67px;
  height: 24px;
  background: #e8e8e8;
  border: 3px solid #d8d8d8;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000e56;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yd_select {
  width: 67px;
  height: 24px;
  background: #001689;
  border: 3px solid #07155e;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yd_normal {
  width: 67px;
  height: 24px;
  background: #e8e8e8;
  border: 3px solid #d8d8d8;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000e56;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ml8 {
  margin-left: 8px;
}
.ml32 {
  margin-left: 12px;
}
.ml30 {
  margin-left: 30px;
}
.label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .alpha {
    background: #e7e7e7;
  }
  .color {
    width: 22px;
    height: 22px;
    background: #000e56;
    border-radius: 50%;
  }
  .hint {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    margin-top: 7px;
  }
  .text_normal {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text_select {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .width62 {
    width: 72px;
    height: 32px;
    border-radius: 18px;
  }
  .color1 {
    background: #c0281b;
  }
  .color2 {
    background: #40d100;
  }
  .color3 {
    background: #00a8c1;
  }
  .color4 {
    background: #e0c800;
  }
  .color5 {
    background: #f48a00;
  }
  .color6 {
    background: #2700ed;
  }
  .color7 {
    background: #d600fd;
  }
  .text {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    margin-top: 4px;
  }
  .color8 {
    background: rgba(151, 32, 79, 1);
  }
  .color9 {
    background: rgba(150, 36, 49, 1);
  }
  .color10 {
    background: rgba(46, 162, 122, 1);
  }
  .color11 {
    background: rgba(31, 68, 135, 1);
  }
  .color12 {
    background: rgba(182, 150, 35, 1);
  }
  .color13 {
    background: rgba(10, 0, 81, 1);
  }
  .color14 {
    background: rgba(24, 130, 148, 1);
  }
  .color15 {
    background: rgba(15, 193, 100, 1);
  }
}
.title {
  font-size: 50px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.pes {
  width: 150px;
  height: 150px;
  margin-top: 21px;
}
.content {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.mt15 {
  margin-top: 15px;
}
.ml20 {
  margin-left: 12px;
}
.ml55 {
  margin-left: 55px;
}
.width514 {
  width: 514px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top_lay {
    position: relative;
    width: 100%;
    height: 78px;
  }
  .top_bg {
    width: 498px;
    height: 62px;
    background: #e7e7e7;
    border: 8px solid #ffffff;
    border-radius: 39px;
  }
  .left_text {
    width: 120px;
    height: 62px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .middle {
    width: 100%;
    height: 62px;

    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pos_abs {
    position: absolute;
    left: 0;
    top: 0;
  }
  .marl8 {
    margin-left: 8px;
  }
  .hint1 {
    font-size: 21px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 25px;
  }
  .left_lay {
    width: 170px;
    height: 49px;
    background: #0abda3;
    border: 6px solid #ffffff;
    border-radius: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text {
      font-size: 38px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .text1 {
      font-size: 38px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-left: 4px;
    }
    .empty_text {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #da3c2d;
    }
    .text2 {
      width: 5px;
      height: 5px;
      background: #ffffff;
      border-radius: 50%;
      margin-left: 15px;
      margin-top: 30px;
    }
  }
  .num_lay {
    width: 514px;
    height: 61px;
    background: #f2f2f2;
    border-radius: 31px;
    display: flex;
    flex-direction: row;
  }
  .mt16 {
    margin-top: 16px;
  }
  .mt36 {
    margin-top: 36px;
  }
}
.baseLine {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex2 {
  flex: 2;
}
.flex1 {
  flex: 1;
}
.mt72 {
  margin-top: 72px;
}
.row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.column_num {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  margin-right: 6px;
  .text {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    margin-top: 10px;
  }
  .line {
    width: 130px;
    height: 2px;
    background: #b9b9b9;
  }
  .value {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #0abda3;
    margin-top: 2px;
  }
  .vs {
    width: 39px;
    height: 25px;
    margin-top: 6px;
  }
}
.analyse {
  width: 334px;
  height: 66px;
  background: #00af0c;
  border: 6px solid #00e210;
  border-radius: 39px;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #010000;
  margin-top: 150px;
  cursor: pointer;
}
.result {
  width: 500px;
  margin-top: 26px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.analyse_chart {
  width: 1400px;
  height: 561px;
  margin-top: 63px;
}
.analyse_tab {
  width: 130px;
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .text1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #686868;
  }
  .text2 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #686868;
  }
}
.width1400 {
  width: 1400px;
}
.tz_select1 {
  width: 94px;
  height: 46px;
  background: #000f60;
  border: 6px solid #0001c1;
  border-top-left-radius: 29px;
  border-bottom-left-radius: 29px;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tz_normal1 {
  width: 100px;
  height: 46px;
  background: #e8e8e8;
  border-top: 6px solid #c4c4c4;
  border-left: 6px solid #c4c4c4;
  border-bottom: 6px solid #c4c4c4;
  border-top-left-radius: 29px;
  border-bottom-left-radius: 29px;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yd_select1 {
  width: 94px;
  height: 46px;
  background: #000f60;
  border: 6px solid #0001c1;
  border-top-right-radius: 29px;
  border-bottom-right-radius: 29px;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yd_normal1 {
  width: 100px;
  height: 46px;
  background: #e8e8e8;
  border-top: 6px solid #c4c4c4;
  border-right: 6px solid #c4c4c4;
  border-bottom: 6px solid #c4c4c4;
  border-top-right-radius: 29px;
  border-bottom-right-radius: 29px;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ys_name {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #606060;
  margin-top: 20px;
}

.top_text {
  width: 100%;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 250px;
  margin-top: 30px;
}

.h240 {
  height: 240px;
}
.column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.red_text {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce2323;
  margin-top: 20px;
}
</style>